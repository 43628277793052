/**
 * Copyright © VNG Realisatie 2023
 * Licensed under the EUPL
 */

import "htmx.org";

import * as dayjs from "dayjs";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/en";
import "dayjs/locale/nl";

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat);
dayjs.locale(window.config.locale);

replaceUTCTimeElementsWithLocalizedText()

document.body.addEventListener("htmx:afterSettle", function (evt) {
  replaceUTCTimeElementsWithLocalizedText()
});

function replaceUTCTimeElementsWithLocalizedText() {
  document.querySelectorAll("time").forEach(($e) => {
    if ($e.dateTime == null || $e.dateTime == "") {
      return;
    }

    // See dayjs docs for possible formats
    let format = "L"; // date
    let titleFormat = "L LTS"; // date time

    elFormat = $e.dataset.format;
    elTitleFormat = $e.dataset.titleFormat;

    if (elFormat != null && elFormat != "") {
      format = elFormat;
    }

    if (elTitleFormat != null && elTitleFormat != "") {
      titleFormat = elTitleFormat;
    }

    datetime = dayjs($e.dateTime, 'YYYY-MM-DD HH:mm:ss');

    $e.innerHTML = datetime.format(format);
    $e.title = datetime.format(titleFormat);
  });
}
